import { DEFAULT_COUNTRY } from '~/utils/enums';
export const userProfile = {
  cp: null,
  zip: null,
  dni: '',
  mobile_prefix: DEFAULT_COUNTRY.id,
  mobile_number: undefined,
  company_name: '',
  company_position: '',
  cxtierra_consent: null,
  is_resident: true
};
