import { DEFAULT_COUNTRY } from '~/utils/enums';
export const createSubscription = {
  userdata: {
    name: {
      givenName: '',
      familyName: ''
    },
    emails: [
      {
        value: '',
        primary: true
      }
    ],
    password: ''
  },
  userPreferences: [],
  userProfession: {
    sector_id: null,
    sector: '',
    cliente_cajamar: null
  },
  userProfile: {
    dni: '',
    mobile_prefix: DEFAULT_COUNTRY.id,
    mobile_number: null,
    company_name: '',
    company_position: '',
    city_id: null,
    city: '',
    province_id: null,
    province: '',
    country: DEFAULT_COUNTRY.country,
    country_id: DEFAULT_COUNTRY.id,
    picture: '',
    zip: null
  },
  additional_info: {},
  legalConsent: true,
  communicationsConsent: true,
  pt_subscription_origin_url: '',
  pt_subscription_origin: '',
  pt_subscription_current_url: '',
  utm: {
    utm_id: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  }
};
