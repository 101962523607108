import { loginUser } from '~/model/loginUser';
import {
  ERROR_CODE,
  ROUTE_NAME_TO_RELOAD,
  CXTIERRA_URL,
  COURSE_BASE_URL,
  HOME_PAGE
} from '~/utils/enums';
import { createUser } from '~/model/createUser';
import { createSubscription } from '~/model/createSubscription';
import { localStorageSetItem } from '~/utils/helpers/store';

export const useAuthentication = () => {
  const { $msal, $modal } = useNuxtApp();
  const { t } = useI18n();
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const { step } = useRegisterCommons();
  const triggerAfterRegister = useCookie('triggerAfterRegister');
  const state = reactive({
    loading: false,
    errorMessage: '',
    errorModal: {},
    claimText: '',
    subtitleText: '',
    userAlreadyExists: false,
    blockedUser: false,
    forwarded: false,
    inputPassword: null
  });
  const {
    loading,
    errorMessage,
    errorModal,
    claimText,
    subtitleText,
    userAlreadyExists,
    blockedUser,
    forwarded,
    inputPassword
  } = toRefs(state);

  const checkEmailExists = (email, nextStep, type) => {
    loading.value = true;
    return store
      .dispatch('profile/checkEmailExists', {
        pathParam: 'checkEmailExistance',
        payload: { email }
      })
      .then(response => {
        if (response.data.existance) {
          userAlreadyExists.value = true;
          claimText.value = t('auth.accountAlreadyExists');
          subtitleText.value = t('auth.subtitleAccountAlreadyExist');
          const { query, path } = route;
          setTimeout(() => {
            if (response.data.permissions.includes(query?.type)) {
              goToSection(query.type, query.redirect ?? path, email);
            } else if (query.type === 'cxtierra' || query.type === 'startup') {
              goToSection(
                query.type,
                `/registro?completeRegister=${query.type}${
                  query.redirect ? `&redirect=${query.redirect}` : ''
                }`,
                email
              );
            } else {
              redirectToLogin(type, query.redirect ?? HOME_PAGE, email);
            }
          }, 3000);
        } else {
          type === 'subscription'
            ? (createSubscription.userdata.emails[0].value = email)
            : (createUser.userdata.emails[0].value = email);
          userAlreadyExists.value = false;
          claimText.value = t('auth.createAccount');
          subtitleText.value = t('auth.subtitleCreateAccount');
          step.value = nextStep;
        }
      })
      .catch(error => {
        const {
          response: { status }
        } = error;
        handleError(status);
      })
      .finally(() => (loading.value = false));
  };

  const goToSection = (type, path, email) => {
    switch (type) {
      case 'cxtierra':
        return window.location.replace(CXTIERRA_URL);
      case 'startup':
        return redirectToLogin(type, path, email);
      default:
        router.push(HOME_PAGE);
    }
  };

  const enrollEvent = email => {
    const slug = route?.query?.slug;
    localStorageSetItem('enrollEvent', {
      slug,
      modality: route?.query?.modality
    });
    const path = `${COURSE_BASE_URL}/${slug}`;
    $msal.signIn(path, { email });
  };

  const statusCodeExists = status => ERROR_CODE.includes(status);

  const redirectToLogin = (type, path = '', email) => {
    if (type === 'subscription') {
      enrollEvent(email);
    } else if (route?.query?.trigger) {
      triggerAfterRegister.value = route?.query?.trigger;
    }
    const redirectPath = path ?? route?.query?.redirect;
    $msal.signIn(redirectPath, { email });
  };

  const handleError = (status, blocked = false) => {
    // checks whether an element is even
    if (blocked) {
      errorModal.value = {
        icon: 'pt-exclamation-circle',
        title: t('profile.verifyBlockedAccountTitle'),
        text: t('profile.verifyBlockedAccountText')
      };
      $modal.open('errorBlocked');
      blockedUser.value = true;
      forwarded.value = false;
    } else {
      return statusCodeExists(status)
        ? (errorMessage.value = t(`forms.errors.signUp.${status}`))
        : (errorMessage.value = t('forms.errors.throwError'));
    }
  };

  const refreshDataAfterLoginOrLogout = hash => {
    if (ROUTE_NAME_TO_RELOAD.includes(route.name)) {
      // For reload page and execute middleware page
      router.push({ path: route.fullPath, hash: `#${hash}` });
    } else {
      // For refresh data and execute fetch hook
      refreshNuxtData();
    }
  };
  const prevStep = () => {
    if (step.value > 1) step.value--;
  };
  const getPermissions = () => {
    const user = $msal?.getActiveAccount() ?? {};
    const list = user?.idTokenClaims?.extension_Permissions ?? '';
    return list
      .split(',')
      .filter(Boolean)
      .map(permission => permission.trim());
  };
  return {
    step,
    loading,
    prevStep,
    claimText,
    loginUser,
    createUser,
    goToSection,
    subtitleText,
    errorMessage,
    inputPassword,
    getPermissions,
    checkEmailExists,
    userAlreadyExists,
    createSubscription,
    refreshDataAfterLoginOrLogout
  };
};
