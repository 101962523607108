export const useRegisterCommons = () => {
  const route = useRoute();
  const store = useStore();
  const step = ref(1);
  const getEmail = computed(() => route.query?.email ?? '');
  const typeCompleteRegister = computed(() => route.query?.completeRegister);
  const getType = computed(() => typeCompleteRegister?.value ?? route.query?.type ?? 'general');
  const common = computed(() => store.getters['strapi/getByField']('common'));
  const sectors = computed(() => store.getters['strapi/getByField']('sectors'));
  const process = computed(() => {
    const data = store.getters['strapi/getByField']('process.register');
    const currentType = data.content_items.find(content => content.type === getType.value);
    return {
      ...data,
      bg_image: currentType.media,
      title: currentType.content,
      success_message: currentType.success_message
    };
  });
  return {
    step,
    common,
    getType,
    sectors,
    process,
    getEmail,
    typeCompleteRegister
  };
};
