import { userProfile } from './userProfile';
import { filterObject } from '~/utils/helpers/object';

export const createUser = {
  userdata: {
    name: {
      givenName: '',
      familyName: ''
    },
    emails: [
      {
        value: '',
        primary: true
      }
    ],
    password: ''
  },
  userProfile: filterObject(userProfile, 'cxtierra_consent', false, true),
  userPreferences: [],
  userProfession: {
    sector_id: '',
    sector: '',
    cliente_cajamar: false
  },
  legalConsent: true,
  communicationsConsent: true,
  pt_subscription_origin_url: '',
  pt_subscription_origin: '',
  pt_subscription_current_url: '',
  utm: {
    utm_id: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  }
};
